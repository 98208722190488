exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advertisers-index-js": () => import("./../../../src/pages/advertisers/index.js" /* webpackChunkName: "component---src-pages-advertisers-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-developers-index-js": () => import("./../../../src/pages/developers/index.js" /* webpackChunkName: "component---src-pages-developers-index-js" */),
  "component---src-pages-in-game-ads-audio-index-js": () => import("./../../../src/pages/in-game-ads/audio/index.js" /* webpackChunkName: "component---src-pages-in-game-ads-audio-index-js" */),
  "component---src-pages-in-game-ads-video-index-js": () => import("./../../../src/pages/in-game-ads/video/index.js" /* webpackChunkName: "component---src-pages-in-game-ads-video-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

